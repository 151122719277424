import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import BackendProvider from '../common/BackendProvider';
import Tabs from '../layout/Tabs';
import Panel from '../layout/Panel';
import Title from '../layout/Title';
import CircularPlot from '../pages/components/CircularPlot';
import { Link } from "react-router-dom";
import { EsgTab } from './company/EsgTab';
import { RiskTab } from './company/RiskTab';
import { FinanceTab } from './company/FinanceTab';

const tabs = ["Summary", "ESG", "Financial", "Risk", "Description", "News Atricles"]
export const CompanyPage =() => {
    const[company, setCompany] = useState({})
    const[esgScore, setEsgScore] = useState({})
    const[stockData, setStockData] = useState([{}])
    let location = useLocation()
    const dummy = [
        {timestamp: new Date("2024-05-01 00:00:00"), high: 123, low: 111, open: 120, close: 115.5},
        {timestamp: new Date("2024-05-02 00:00:00"), high: 130, low: 109.4},
        {timestamp: new Date("2024-05-03 00:00:00"), high: 125, low: 117.0},
        {timestamp: new Date("2024-05-04 00:00:00"), high: 126.6, low: 110.5},
        {timestamp: new Date("2024-05-05 00:00:00"), high: 128, low: 113.4},
        {timestamp: new Date("2024-05-06 00:00:00"), high: 133.6, low: 109.4},
        {timestamp: new Date("2024-05-07 00:00:00"), high: 124.5, low: 121.3},  
        {timestamp: new Date("2024-05-08 00:00:00"), high: 120.7, low: 114.5}]
const dummy2 = [
        {timestamp: 1717429000, high: 123, low: 111, open: 120, close: 115.5},
        {timestamp: 1717429100, high: 130, low: 109.4},
        {timestamp: 1717429200, high: 125, low: 117.0},
        {timestamp: 1717429300, high: 126.6, low: 110.5},
        {timestamp: 1717429400, high: 128, low: 113.4},
        {timestamp: 1717429500, high: 133.6, low: 109.4},
        {timestamp: 1717429600, high: 124.5, low: 121.3},  
        {timestamp: 1717429700, high: 120.7, low: 114.5}]


    const[loading, setLoading] = useState(true)
    const[tab, setTab] = useState(0)
    let { symbol } = useParams(); 
    useEffect(() => {
        setLoading(true)
        BackendProvider.getOne("companies", {id: symbol}).then((response) => {
            setCompany(response.data.company)
            setEsgScore(response.data.esg_score)
            setStockData(response.data.stock_data)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading companies", {})
                setLoading(false)
            }
        )
    },[location])
    
    if (company === null) {
        return <div>Company not found</div>
    }

    return (
      <Panel>
        <Title>{company.name} ({company.symbol})</Title>
        <Tabs tabs={tabs} onSelection={setTab}/>
        {tab === 0 &&
         <div className="mt-4 w-full h-fit bg-backgroundbody-background-color rounded border border-solid border-controlsdivider-color20 shadow-shadow-base text-left">
          <CircularPlot
            title={`↑ ${company.name} (${company.symbol}) stock price ($)`} 
            data={stockData}
          />
          </div>
        }
        {tab === 1 &&
        <EsgTab esgScore={esgScore}/>
        }
        {tab === 2 &&
        <FinanceTab company={company} stockData={stockData}/>
        }
        {tab === 3 &&
        <RiskTab/>
        }
         {tab === 4 &&
        <div className="mt-4 w-100 bg-backgroundbody-background-color rounded border border-solid border-controlsdivider-color20 shadow-shadow-base text-left">
          <div className="font-normal p-2" ><span className="font-semibold">Sector:</span> {company.sector?.name}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Industry:</span> {company.industry?.name}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Region:</span> {company.region}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Country:</span> {company.country?.name}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Market Cap:</span> {company.market_cap}</div>
          <div className="font-normal p-2" ><span className="font-semibold">CEO:</span> {company.ceo}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Number of Employees:</span> {company.employees}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Website:</span>{company.website}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Description:</span> {company.description}</div>
        </div>
        }
        {tab === 5 &&
        <div>
          <ul>
            {company.news && company.news.map((news) => {
              return(
                <li key={news.id}>
                  <div className="mt-2 w-100 py-2 px-4 bg-backgroundbody-background-color rounded border border-solid border-controlsdivider-color20 shadow-shadow-base text-left">
                  <Link to = {`/news/${news.id}`}>
                    <span className="inline-flex w-4/5 font-semibold overflow-hidden">{news.headline}</span>
                  </Link>
                  <span className="font-normal">{(new Date(news.published_at)).toLocaleString("en-GB")}</span>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      }
      </Panel>
    );
}