const cx = require('classnames');

export default function Button({ children, className, onClick, variant, disabled, ...rest }) {
    return (
        <button
            onClick={onClick}
            className={cx(`bg-primary border px-4 py-4 rounded-lg`, className, disabled ? 'bg-slate-300 text-white' : (variant === 'primary' ? `text-white bg-primary-100` : `text-primary`))}
            {...rest}
            disabled={disabled}
        >
            {children}
        </button>
    );
}