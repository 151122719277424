import{ useEffect, useState, useRef } from 'react';
import * as Plot from "@observablehq/plot";
import { timeDay, timeMillisecond, timeMonth, timeSecond } from 'd3';

const VerticalBarsChart = ({ 
    data,
    title,
    width = 1200,
    height = 480,
    marginTop = 20,
    marginRight = 30,
    marginBottom = 30,
    marginLeft = 40,
}) => {
    const ref = useRef();
    const [sort, setSort] = useState("Alphabetical");

    useEffect(() => {
        const barChart = Plot.plot({
            marginTop: marginTop,
            marginRight: marginRight,
            marginBottom: marginBottom,
            marginLeft: marginLeft,
            height: height,
            width: width,
            grid: true,
            x: {
                tickFormat: (d) => new Date(d).toLocaleDateString(),
                ticks: timeDay.every(1),
                label: "Date"
            },
            y: {label: title, ticks: 4},
            color: {domain: [-1, 0, 1], range: ["#e41a1c", "#000000", "#4daf4a"]},
            marks: [
            Plot.ruleX(data, {
                x: "timestamp",
                y1: "low",
                y2: "high"
            }),
            Plot.ruleX(data, {
                x: "timestamp",
                y1: "open",
                y2: "close",
                stroke: (d) => Math.sign(d.close - d.open),
                strokeWidth: 4,
                strokeLinecap: "round"
            })
            ],
        });
        ref.current.append(barChart);
        return () => barChart.remove();
    }, [data, sort]);

  return (
    <div>
      <div ref={ref}></div>
    </div>
  );
}

export default VerticalBarsChart;