import React from "react";
import Table from "./../../layout/Table.jsx";
import BackendProvider from "../../common/BackendProvider.jsx";
import { toast, ToastContainer } from "react-toastify";

export const AdminEsgIndicators = () => {
  const esgPillars = [
    {value: 'E', label: 'Environment'},
    {value: 'S', label: 'Social'},
    {value: 'G', label: 'Governance'}
  ]
  const esgReportingStandards = [
    {value: '1', label: 'Corporate Sustainability Reporting Directive'}
  ]
  const handleAddRow = (newRow) => {
    console.log(newRow)
    BackendProvider.create("admin/esg_indicators", newRow).then((response) => {
      console.log(response)
    }).catch((e) => {
      toast.error(e.message?.message)
      console.log(e)
    });
  }
  return (
    <div className="p-8 border-l">
      <ToastContainer />
      <div className="text-3xl font-bold mb-8">ESG Indicators</div>
      <div className="grid">
        <Table 
            columns={["Name", "Description", "ESG Pillar", "ESG Reporting Standard", "ESG Data Group"]}
            keys={[
              {name: 'name', type: 'text', format: (d) => d, class: "text-left"},
                {name: 'description', type: 'text', format: (d) => d, class: "text-left"},
                {name: 'esg_pillar', type: 'select', format: (d) => d, options: esgPillars, class: "text-left"},
                {name: 'esg_reporting_standard', type: 'select', format: (d) => d, options: esgReportingStandards, class: "text-left"},
                {name: 'esg_data_group', type: 'text', format: (d) => d, class: "text-left"}
            ]}
            onAddRow={handleAddRow}
        />x
      </div>
    </div>
  );
};
