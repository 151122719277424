import * as React from 'react';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import FlagIcon from '@mui/icons-material/Flag';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

const RiskTile = ({risk}) => {
    return(
        <div className="p-4 bg-white text-gray-600 bg-background body-background-color rounded border border-solid shadow-shadow-base text-left">
            <div className="flex">
            <div className="p-2">{risk.icon}</div>
            <div className="p-2 text-xl font-bold">{risk.title}</div>
            </div>
            <p className="text-sm text-gray-600">{risk.description}</p>
        </div>
    )
}

export const RiskTab =() => {
    const risks = [
        {
            "title": "Severe Negative Supply Shock", 
            "description": "A severe negative supply shock could result from a significant disruption to the supply chain, such as a natural disaster or a pandemic. This could lead to a sharp increase in prices and a decline in output, which could have a negative impact on the economy.",
            "icon": <LocalShippingIcon style={{ fontSize: 40, color: "#FF5733" }}/>
        }, {
            "title": "Severe Negative Demand Shock", 
            "description": "A severe negative demand shock could result from a sharp decline in consumer spending, such as during a recession. This could lead to a decline in output and an increase in unemployment, which could have a negative impact on the economy.",
            "icon": <ShoppingCartIcon style={{ fontSize: 40, color: "#FF5733" }}/>
        }, {
            "title": "Severe domestic and global recessions, countercyclical approach to severity", 
            "description": "Severe domestic and global recessions could result from a combination of negative supply and demand shocks. A countercyclical approach to severity could involve implementing policies to stimulate the economy during a recession, such as increasing government spending or cutting interest rates.",
            "icon": <TrendingDownIcon  style={{ fontSize: 40, color: "#FF5733" }}/>
        }, {
            "title": "Domestic Recession", 
            "description": "A domestic recession could result from a decline in consumer spending, such as during a period of economic uncertainty. This could lead to a decline in output and an increase in unemployment, which could have a negative impact on the economy.",
            "icon": <FlagIcon  style={{ fontSize: 40, color: "#FF5733" }}/>
        }, {
            "title": "Global Recession", 
            "description": "A global recession could result from a decline in global economic activity, such as during a period of economic uncertainty. This could lead to a decline in output and an increase in unemployment, which could have a negative impact on the economy.",
            "icon": <PublicOffIcon style={{ fontSize: 40, color: "#FF5733" }}/>
        }, {
            "title": "Financial Market Stress", 
            "description": "Financial market stress could result from a decline in investor confidence, such as during a period of economic uncertainty. This could lead to a decline in asset prices and an increase in market volatility, which could have a negative impact on the economy.",
            "icon": <MoneyOffIcon  style={{ fontSize: 40, color: "#FF5733" }}/>
        }
    ]
    return(
        <div className="mt-4 w-100 ">
            <div className="grid grid-cols-2 gap-4 p-4">
                {risks.map((risk, index) => {
                    return <RiskTile key={index} risk={risk}/>
                })}
            </div>
        </div>
    )
}