import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
  
const data = [
    { type: 'ESG', share: 10, value: 1, color: '#4CAF50'},
  { type: 'Financial', share: 10, value: 1, color: '#dddddd' },
  
  { type: 'Risk', share: 10, value: 1, color: '#dddddd'}
]
const width = 900;
const height = 500;


//chart component
const CircularPlot = () => {

    const svgRef = useRef();
    const labelArcs = d3.arc()
        .innerRadius( 0.95 * height / 2 )
        .outerRadius( 0.95 * height / 2 )

    useEffect(() => {
        const radius = Math.min(width, height) / 2;
        const pie = d3.pie()
            .value(d => d.value)
        
        const pieArcs = pie(data)
console.log(pieArcs)
        const svg = d3.select(svgRef.current);

        const outerRadiusScale = d3.scaleLinear()
            .domain([d3.min(data, d => d.value), d3.max(data, d => d.value)])
            .range([radius * 0.8, radius]); 


        const arc = d3.arc()
            .innerRadius( 0.5 * height / 2 )
            .outerRadius( d => outerRadiusScale(d.value))
            .startAngle( d => d.startAngle  )
            .endAngle( d => d.startAngle + Math.PI * 2/ 3)

        svg.append('g')
            .attr('class', 'donut-container')
            .attr('transform', `translate(${ width / 2 },${ height / 2 })`)
            .selectAll('path')
            .data( pieArcs )
            .join('path')
            .style('stroke', 'white')
            .style('stroke-width', 2)
            .style('fill', d =>  d.data.color )
            .attr('d', arc)
   
        const text = svg.append('g')
            .attr('class', 'labels-container')
            .attr('transform', `translate(${ width / 2 },${ height / 2 })`)
            .selectAll('text')
            .data( pieArcs )
            .join('text')
            .attr('transform', d => `translate(${ labelArcs.centroid(d) })`)
            .attr('text-anchor', 'middle')

        text.selectAll('tspan')
            .data( d => [ 
                d.data.type,
            ])
            .join('tspan')
            .attr('x', 0)
            .style('font-family', 'sans-serif')
            .style('font-size', 12)
            .style('font-weight', (d,i) => i ? undefined : 'bold')
            .style('fill', '#222') 
            .attr('dy', (d,i) => i ? '1.2em' : 0 )
            .text(d => d)
  }, [data]);

  return (
    
      <svg width={width} height={height} ref={svgRef}>
        
        
      </svg>
    
  );
};

export default CircularPlot;