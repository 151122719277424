import Panel from "../layout/components/Panel";


export function RegisterSuccess () {
return (
        <>
            <Panel title="Registration Successful">
                <p className="text-normal text-center text-general-80">Please check your email and click the confirmation link</p>
            </Panel>
        </>
    )
}