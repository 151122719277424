import { AdminTopMenu } from "./AdminTopMenu.jsx";
import { AdminLeftMenu } from "./AdminLeftMenu.jsx";


export const AdminPrivateHome = ({children}) => {
    return (
        <div className="bg-[#F5F6FA] flex flex-row justify-center w-full">
            <div className="lg:w-[1440px] w-full h-[1490px]">
                <AdminTopMenu username="John Doedoe"/>
                <div className="justify-center relative grid grid-cols-10">
                    <div className="col-span-2 lg:block hidden"> 
                        <AdminLeftMenu/>
                    </div>
                    <div className="lg:col-span-8 col-span-10">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}