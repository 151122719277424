import { Link } from "react-router-dom";

export const NewsListItem =({newsitem}) => {
    return(
        <div className="relative mb-2 rounded border-red-800 font-black">
            <div className="grid items-start top-0 ml-4 mr-4 left-2 w-100 h-16 bg-backgroundbody-background-color rounded border border-solid border-controlsdivider-color20 shadow-shadow-base">
                <div className="inline-flex mt-3 pl-4 items-start gap-2">
                    <span className="relative w-4/6 text-left font-semibold text-zinc-950  overflow-hidden">
                        <Link to = {`/news/${newsitem.id}`  }>{newsitem.headline}</Link>
                    </span>
                    <span className="relative overflow-hidden font-normal text-slate-500 text-center  whitespace-nowrap">
                        {(new Date(newsitem.published_at)).toLocaleString("en-GB")}
                    </span>
                    <img
                        className="inline-flex w-px h-12 object-cover"
                        alt="Line"
                        src="/images/line-v.svg"
                    />
                    <span className="relative overflow-hidden font-normal text-slate-500 text-center  whitespace-nowrap">
                        {newsitem.source_id}
                    </span>
                    <img
                        className="inline-flex w-px h-12 object-cover"
                        alt="Line"
                        src="/images/line-v.svg"
                    />
                    <span className="relative overflow-hidden font-normal text-slate-500 text-center  whitespace-nowrap">
                        {newsitem.symbols}
                    </span>
                </div>
                
            </div>        
        </div>
    )
}