export const DashboardIcon = ({fill}) => {
    if (fill === "general-70") {
        fill = "#7E84A3"
    } else if (fill === "white") {
        fill = "#FFFFFF"
    }
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9 3C10.1046 3 11 3.89543 11 5V9C11 10.1046 10.1046 11 9 11H5C3.89543 11 3 10.1046 3 9V5C3 3.89543 3.89543 3 5 3H9ZM9 5H5V9H9V5ZM19 3C20.1046 3 21 3.89543 21 5V9C21 10.1046 20.1046 11 19 11H15C13.8954 11 13 10.1046 13 9V5C13 3.89543 13.8954 3 15 3H19ZM19 5H15V9H19V5ZM11 15C11 13.8954 10.1046 13 9 13H5C3.89543 13 3 13.8954 3 15V19C3 20.1046 3.89543 21 5 21H9C10.1046 21 11 20.1046 11 19V15ZM5 15H9V19H5V15ZM19 13C20.1046 13 21 13.8954 21 15V19C21 20.1046 20.1046 21 19 21H15C13.8954 21 13 20.1046 13 19V15C13 13.8954 13.8954 13 15 13H19ZM19 15H15V19H19V15Z" fill={fill}/>
        </svg>
    )
}

export const InvestmentProfilesIcon = ({fill}) => {
    if (fill === "general-70") {
        fill = "#7E84A3"
    } else if (fill === "white") {
        fill = "#FFFFFF"
    }
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17 11C19.0871 11 20.9253 12.0656 22.0002 13.6825L21.9993 17H14L13.999 18.952L14.0215 20H2V19C2 15.6863 4.68629 13 8 13C9.37834 13 10.6509 13.4657 11.6662 14.2518C12.662 12.3204 14.6768 11 17 11ZM8 15C6.26204 15 4.78296 16.1084 4.23109 17.6569L4.16936 17.8447L4.126 18H11.873L11.8362 17.8625C11.3827 16.3295 10.0355 15.1846 8.4051 15.0203L8.19987 15.0049L8 15ZM17 13C15.6048 13 14.3764 13.7144 13.6606 14.7973L13.535 15H20.464L20.3707 14.845C19.6927 13.7872 18.5304 13.0708 17.2007 13.005L17 13ZM8 4C10.2091 4 12 5.79086 12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8C4 5.79086 5.79086 4 8 4ZM17 4C18.6569 4 20 5.34315 20 7C20 8.65685 18.6569 10 17 10C15.3431 10 14 8.65685 14 7C14 5.34315 15.3431 4 17 4ZM8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6ZM17 6C16.7239 6 16 6 16 6C16 6 16 6.72386 16 7C16 7.27614 16 8 16 8C16 8 16.7239 8 17 8C17.2761 8 18 8 18 8C18 8 18 7.27614 18 7C18 6.86441 18 6 18 6C18 6 17.1356 6 17 6Z" fill={fill}/>
        </svg>
    )
}

export const CompaniesIcon = ({fill}) => {
    if (fill === "general-70") {
        fill = "#7E84A3"
    } else if (fill === "white") {
        fill = "#FFFFFF"
    }
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.5459 3.57029C11.9641 3.15205 12.5498 2.9466 13.1377 3.01192L21 3L20.9881 10.8623C21.0534 11.4502 20.848 12.0359 20.4297 12.4541L11.4541 21.4297C10.6937 22.1901 9.46092 22.1901 8.70056 21.4297L2.57027 15.2994C1.80991 14.5391 1.80991 13.3063 2.57027 12.5459L11.5459 3.57029ZM13 5L3.94704 13.9227L10.0773 20.053L19 11V5L13 5ZM14 8L16 10L14 12L12 10L14 8Z" fill={fill}/>
        </svg>
    )
}

export const DealRoomIcon = ({fill}) => {
    if (fill === "general-70") {
        fill = "#7E84A3"
    } else if (fill === "white") {
        fill = "#FFFFFF"
    }
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14 3C15.1046 3 16 3.89543 16 5V7H19C20.1046 7 21 7.89543 21 9V18C21 19.1046 20.1046 20 19 20H5C3.89543 20 3 19.1046 3 18V9C3 7.89543 3.89543 7 5 7H8V5C8 3.89543 8.89543 3 10 3H14ZM19 9H5V18H19V9ZM14 5H10V7H14V5Z" fill={fill}/>
        </svg>
    )
}

export const LearningIcon = ({fill}) => {
    if (fill === "general-70") {
        fill = "#7E84A3"
    } else if (fill === "white") {
        fill = "#FFFFFF"
    }
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M21 18C21.5523 18 22 18.4477 22 19C22 19.5523 21.5523 20 21 20H3C2.44772 20 2 19.5523 2 19C2 18.4477 2.44772 18 3 18H21ZM18 5C19.1046 5 20 5.89543 20 7V15C20 16.1046 19.1046 17 18 17H6C4.89543 17 4 16.1046 4 15V7C4 5.89543 4.89543 5 6 5H18ZM18 7H6V15H18V7Z" fill={fill}/>
        </svg>
    )
}

export const ContactUsIcon = ({fill}) => {
    if (fill === "general-70") {
        fill = "#7E84A3"
    } else if (fill === "white") {
        fill = "#FFFFFF"
    }
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10H10L10.0055 9.85074C10.0818 8.81588 10.9456 8 12 8C13.1046 8 14 8.89543 14 10C14 11.1046 13.1046 12 12 12C11.4477 12 11 12.4477 11 13V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V13.874C14.7252 13.4299 16 11.8638 16 10ZM13 18V16H11V18H13Z" fill={fill}/>
        </svg>
    )
}

