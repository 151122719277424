import React, {useState, useRef, useEffect} from "react";
import { Link } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import {AuthContext} from './../../App'
import { ArrowDown } from "./../images/Arrows";

const useAuth = () => {
    return React.useContext(AuthContext);
}

function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export const AdminTopRightDropdown = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { username} = props;
    const { onLogout } = useAuth();
    const wrapperRef = useRef(null);
    const closeDropdown = () => {
      setDropdownOpen(false);
    }

    useOutsideAlerter(wrapperRef, closeDropdown);
    return (
        <div className="flex justify-center items-center" ref={wrapperRef}>
          <div className="h-10 w-10 rounded-full bg-slate-200 mx-2">
              <span className="hidden text-right lg:block mx-4 py-2">
                <span className="block text-sm font-medium text-black dark:text-white">{username[0]}</span>
              </span>
          </div>
          <div><a
              className="flex items-center gap-4 cursor-pointer ml-2 text-general-80"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >{username}</a></div>
          <div>
          <div className="ml-4 mr-4"><ArrowDown fill="general-70" /></div>
            
        </div>
        {dropdownOpen && 
          <div
          className="absolute z-10 right-40 mt-56 flex flex-col w-60 rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
        >
         <ul
            className="flex flex-col gap-5 border-b border-stroke px-6 py-4 dark:border-strokedark list-none"
          >
            <li>
              <Link to="/my/profile"
                onClick={() => setDropdownOpen(false)}
                className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out text-general-100 hover:text-primary lg:text-base"
              >
                <PersonIcon/>
                My Profile
              </Link>
            </li>
            <li>
              <Link 
                to="/settings"
                onClick={() => setDropdownOpen(false)}
                className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
              >
                <SettingsIcon/>
                Account Settings
              </Link>
            </li>
            <li>
              <Link 
                onClick={onLogout}
                to="#"
                className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
              >
                <LogoutIcon/>
                Log Out
              </Link>
            </li>
          </ul>
        </div>
        }
       </div>
    )
}