import dataProvider from './DataProvider';
import {Auth} from './AuthProvider';

export default {
    getList: async (resource, params, props) => {
        try {
            await Auth.checkAuth();            
        } catch (e) {
            props.childProps.onLogout();
        }
        return dataProvider.getList(resource, params);
    },
    getOne: async (resource, params, props) => {
        try {
            await Auth.checkAuth();            
        } catch (e) {
            props.childProps.onLogout();
        }
        return dataProvider.getOne(resource, params)
    },
    getMany: async (resource, params, props) => {
        try {
            await Auth.checkAuth();            
        } catch (e) {
            props.childProps.onLogout();
        }
        return dataProvider.getMany(resource, params)
    },
    getManyReference: async (resource, params, props) => {
        try {
            await Auth.checkAuth();            
        } catch (e) {
            props.childProps.onLogout();
        }
        return dataProvider.getManyReference(resource, params)
    },
    update: async (resource, params, props) => {
        try {
            await Auth.checkAuth();            
        } catch (e) {
            props.childProps.onLogout();
        }
        return dataProvider.update(resource, params)
    },
    updateMany: async (resource, params, props) => {
        try {
            await Auth.checkAuth();            
        } catch (e) {
            props.childProps.onLogout();
        }
        return dataProvider.updateMany(resource, params) 
    },
    create: async (resource, params, props) => {
        try {
            await Auth.checkAuth();            
        } catch (e) {
            props.childProps.onLogout();
        }
        return dataProvider.create(resource, params)
    },
    delete: async (resource, params, props) => {
        try {
            await Auth.checkAuth();            
        } catch (e) {
            props.childProps.onLogout();
        }
        return dataProvider.delete(resource, params)
    },
    deleteMany: async (resource, params, props) => {
        try {
            await Auth.checkAuth();            
        } catch (e) {
            props.childProps.onLogout();
        }
        return dataProvider.deleteMany(resource, params) 
    }
}