export default function Select({ label, id, name, options, onChange, ...rest }) {
    const handleChange = (e) => {
        onChange(e.target.value);
    };
    return (
        <div className="flex flex-col">
            <label className="text-left text-general-80 text-sm" htmlFor={id}>{label}</label>
            <select 
                className="border text-general-100 bg-white rounded-lg border-[#D9E1EC] active:border-[#D9E1EC] placeholder-general-60 p-2" 
                id={id} 
                onChange={handleChange}
                name={name} {...rest}>
                {options?.map((option) => (
                    <option key={option.id} value={option.id}>{option.name}</option>
                ))}
            </select>
        </div>
    );
}