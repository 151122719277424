import React, { useEffect, useState } from 'react';

export function NewsFilter({esgKeywords, onChange, onScoreChange}) {
    const[score, setScore] = useState('')
    useEffect(() => {
        onScoreChange(score)
    },[score])
    return (
        <div className="mt-4 w-100 p-4 bg-backgroundbody-background-color rounded border border-solid border-controlsdivider-color20 shadow-shadow-base text-left">
            <h2>Filter</h2>
            <label>Keyword</label>
            <select className="p-2 m-4" onChange={onChange}>
                <option value="">----</option>
                {esgKeywords.map((esgKeyword, i) => {
                    return <option key={i} value={esgKeyword.id}>{esgKeyword.text} ({esgKeyword.category})</option>
                })}
            </select>
            <label>Min. Score</label>
            <input type="text" placeholder="score" className="p-2 m-4" onChange={setScore}/>
        </div>
    );
}
