import Button from "../layout/components/Button";
import Panel from "../layout/Panel";
import Input from "../layout/components/Input";
import Title from "../layout/Title";
import Tabs from "../layout/Tabs";
import { useState, useEffect } from "react";
import BackendProvider from '../common/BackendProvider';
import { ToastContainer, toast } from "react-toastify";

export const Profile = () => {
    const tabs = ['Profile', 'Change Password']
    const[tab, setTab] = useState(0)
    const [name, setName] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
    const [loading, setLoading] = useState(true);
    
    const handleNameChange = (e) => {
       setName(e.target.value);
    };
    const handleOldPasswordChange = (e) => {
       setOldPassword(e.target.value);
    };
    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };
    const handleNewPasswordConfirmationChange = (e) => {
        setNewPasswordConfirmation(e.target.value);
    };

    const handleUpdate = () => {
        BackendProvider.update("users", {id: "me", data: {'name': name}}).then((response) => {
            setName(response.data.name)
            const user = JSON.parse(localStorage.getItem('user'));
            user.name = response.data.name;
            localStorage.setItem('user', JSON.stringify(user));
            toast.success("Profile updated successfully", {})
        }).catch((error) => {
            toast.error(error.message.message, {})
        })
    }

    const handlePasswordUpdate = () => {
        if (newPassword !== newPasswordConfirmation) {
            toast.error("New password and confirmation do not match", {})
            return
        }
        BackendProvider.update("users", {id: "me/password", data: {'old_password': oldPassword, 'new_password': newPassword}}).then((response) => {
            setOldPassword('')
            setNewPassword('')
            setNewPasswordConfirmation('')
            toast.success("Password updated successfully", {})
        }).catch((error) => {
            toast.error(error.message.message, {})
        })
    }

    useEffect(() => {BackendProvider.getOne("users", {id: "me"}).then((response) => {
            setName(response.data.name)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading user data", {})
                setLoading(false)
            }
        )
    },[])

    return (
        <div>
            <Panel>
                <ToastContainer/>
                <Title>Profile</Title>
                <Tabs tabs={tabs} onSelection={setTab}/>
                {tab === 0 &&
                <div className="self-start p-4 rounded">
                    <div className="form-group mb-4 text-center">
                        <Input 
                            type="text" 
                            label="Name" 
                            id="username" 
                            name="username" 
                            value={name} 
                            onChange={handleNameChange}
                        />
                    </div>
                    <Button variant="primary" onClick={handleUpdate} >Update</Button>
                </div>
                }
                {tab === 1 &&
                <div className="self-start p-4 rounded">
                    <div className="form-group mb-4 text-center">
                        <Input 
                            type="password" 
                            label="OldPassword" 
                            id="oldpassword" 
                            name="oldpassword" 
                            value={oldPassword} 
                            onChange={handleOldPasswordChange}
                        />
                    </div>
                    <div className="form-group mb-4 text-center">
                        <Input 
                            type="password" 
                            label="New Password" 
                            id="newpassword" 
                            name="newpassword" 
                            value={newPassword} 
                            onChange={handleNewPasswordChange}
                        />
                    </div>
                    <div className="form-group mb-4 text-center">
                        <Input 
                            type="password" 
                            label="New Password Confirmation" 
                            id="newpasswordconfirmation" 
                            name="newpasswordconfirmation" 
                            value={newPasswordConfirmation} 
                            onChange={handleNewPasswordConfirmationChange}
                        />
                    </div>
                    <Button variant="primary" onClick={handlePasswordUpdate} >Update</Button>
                </div>
                }
            </Panel>
        </div>
    );
}
