import React from "react";
import {AuthContext} from "../App";

const useAuth = () => {
    return React.useContext(AuthContext);
}

export const PublicHome = ({children}) => {
    let { loggedIn } = useAuth();

    if (loggedIn && location.pathname === "/") {
        return <Content><Navigate to="/dashboard" />{children}</Content>;
    }
    return (
        <div className="bg-[#F5F6FA] flex flex-col lg:flex-row items-center justify-center min-h-screen">
            {children}
        </div>
    );
}