import Tabs from "../../layout/Tabs"
import Table from "../../layout/Table"
import { useState, useEffect } from "react"
import BackendProvider from '../../common/BackendProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const tabs = ["ESG Keyphrases", "Sentiment Analysis"]
export const Settings =() => {
  const[tab, setTab] = useState(0)
  const[esgkeyphrases, setEsgkeyphrases] = useState([])
  const[sentimentkeyphrases, setSentimentkeyphrases] = useState([])
  const[loading, setLoading] = useState(true)
  useEffect(() => {
        setLoading(true)
        BackendProvider.getList("esg_keyphrases", {pagination:{page: 0, perPage: 200}}).then((response) => {
            setEsgkeyphrases(response.data.esgkeyphrases)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading esgkeyphrases", {})
                setLoading(false)
            }
        )
        BackendProvider.getList("sentiment_keyphrases", {pagination:{page: 0, perPage: 200}}).then((response) => {
            const result = response.data.sentimentkeyphrases
            let sentimentkeyphrases = []
            result.map((row) => {
                sentimentkeyphrases = [...sentimentkeyphrases, {
                  id: row.id, 
                  text: row.text, 
                  esg_keyphrase: row.esg_keyphrase ? row.esg_keyphrase.text : "", 
                  sentiment_score: row.sentiment_score,
                  threshold: row.threshold
                }]
            })
            setSentimentkeyphrases(sentimentkeyphrases)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading sentimentkeyphrases", {})
                setLoading(false)
            }
        )
    },[])

  const handleDeleteEsgKeyphrase = (id) => {
    BackendProvider.delete("esg_keyphrases", id).then((response) => {
        const newEsgKeyphrases = esgkeyphrases.filter((row) => row.id !== id)
        setEsgkeyphrases(newEsgKeyphrases)
    }).catch(
        (e) => {
            toast.error("Error while deleting esg keyphrase" + e.message.message, {})
        }
    )
  }

  const handleAddEsgKeyphrase = (row) => {
    BackendProvider.create("esg_keyphrases", row).then((response) => {
        const newEsgKeyphrases = [...esgkeyphrases, {
          id: response.data.esgkeyphrase.id, 
          text: response.data.esgkeyphrase.text, 
          category: response.data.esgkeyphrase.category
        }]
        setEsgkeyphrases(newEsgKeyphrases)
    }).catch(
        (e) => {
            toast.error("Error while adding esg keyphrase: " + e.message.message, {})
        }
    )
  }

  const handleDeleteSentimentKeyphrase = (id) => {
    BackendProvider.delete("sentiment_keyphrases", id).then((response) => {
        const newSentimentKeyphrases = sentimentkeyphrases.filter((row) => row.id !== id)
        setSentimentkeyphrases(newSentimentKeyphrases)
    }).catch(
        (e) => {
            toast.error("Error while deleting sentiment keyphrase" + e.message.message, {})
        }
    )
  }

  const handleAddSentimentKeyphrase = (row) => {
    row.esg_keyphrase_id = row.esg_keyphrase
    BackendProvider.create("sentiment_keyphrases", row).then((response) => {
        const newSentimentKeyphrases = [...sentimentkeyphrases, {
          id: response.data.sentimentkeyphrase.id, 
          text: response.data.sentimentkeyphrase.text, 
          esg_keyphrase: response.data.sentimentkeyphrase.esg_keyphrase ? response.data.sentimentkeyphrase.esg_keyphrase.text : "", 
          sentiment_score: response.data.sentimentkeyphrase.sentiment_score,
          threshold: response.data.sentimentkeyphrase.threshold
        }]
        setSentimentkeyphrases(newSentimentKeyphrases)
    }).catch(
        (e) => {
            toast.error("Error while adding sentiment keyphrase" + e.message.message, {})
        }
    )
  }

  const esgKeyphraseOptions = esgkeyphrases.map((row) => {
    return {value: row.id, label: row.text}
  })

  return (
    <div className="mt-4 col-span-4  bg-white rounded-[4px] shadow-[0px_0px_1px_#171a1f12,0px_0px_2px_#171a1f1f]">
      <ToastContainer />
      <div className="mt-4  [font-family:'Lexend-Bold',Helvetica] font-bold text-[#565d6d] text-[20px] tracking-[0] leading-[30px] whitespace-nowrap">
        Settings
      </div>
      <Tabs tabs={tabs} onSelection={setTab}/>
      {tab === 0 &&
        <Table 
          columns={["Text", "Category"]} 
          keys={[{name: 'text', type: 'text'}, {name: 'category', type: 'text'}]} 
          data={esgkeyphrases}
          onDeleteRow={(id) => handleDeleteEsgKeyphrase(id)}
          onAddRow={(row) => handleAddEsgKeyphrase(row)}
          />
      }
      {tab === 1 &&
        <Table 
          columns={["Text", "ESG Keyphrase", "Sentiment Score", "Threshold"]} 
          keys={[
            {name: 'text', type: "text"}, 
            {name: 'esg_keyphrase', type: "select", options: esgKeyphraseOptions}, 
            {name: 'sentiment_score', type: "text"},
            {name: 'threshold', type: "text"}
          ]} 
          data={sentimentkeyphrases}
          onDeleteRow={(id) => handleDeleteSentimentKeyphrase(id)}
          onAddRow={(row) => handleAddSentimentKeyphrase(row)}
          />
      }
    </div>
    
  )
}