import { TopMenu } from "./../layout/TopMenu.jsx";
import { LeftMenu } from "./LeftMenu.jsx";


export const PrivateHome = ({children, user}) => {
    const username = user ? user.name : '';
    return (
        <div className="bg-[#F5F6FA] flex flex-row justify-center w-full">
            <div className="lg:w-[1440px] w-full h-[1490px]">
                <TopMenu username={username}/>
                <div className="justify-center relative grid grid-cols-10">
                    <div className="col-span-2 lg:block hidden"> 
                        <LeftMenu/>
                    </div>
                    <div className="lg:col-span-8 col-span-10">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}