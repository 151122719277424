export const DashboardIcon = ({fill, w, h}) => {
    if (fill === "general-70") {
        fill = "#7E84A3"
    } else if (fill === "white") {
        fill = "#FFFFFF"
    } else if (fill === "primary-100") {
        fill = "#1E5EFF"
    }
    return(
        <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14 3C15.1046 3 16 3.89543 16 5V7H19C20.1046 7 21 7.89543 21 9V18C21 19.1046 20.1046 20 19 20H5C3.89543 20 3 19.1046 3 18V9C3 7.89543 3.89543 7 5 7H8V5C8 3.89543 8.89543 3 10 3H14ZM19 9H5V18H19V9ZM14 5H10V7H14V5Z" fill={fill}/>
        </svg>
    )
}

export const CompaniesIcon = ({fill, w, h}) => {
    if (fill === "general-70") {
        fill = "#7E84A3"
    } else if (fill === "white") {
        fill = "#FFFFFF"
     } else if (fill === "primary-100") {
        fill = "#1E5EFF"
    }
    return(
        <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.5459 3.57029C11.9641 3.15205 12.5498 2.9466 13.1377 3.01192L21 3L20.9881 10.8623C21.0534 11.4502 20.848 12.0359 20.4297 12.4541L11.4541 21.4297C10.6937 22.1901 9.46092 22.1901 8.70056 21.4297L2.57027 15.2994C1.80991 14.5391 1.80991 13.3063 2.57027 12.5459L11.5459 3.57029ZM13 5L3.94704 13.9227L10.0773 20.053L19 11V5L13 5ZM14 8L16 10L14 12L12 10L14 8Z" fill={fill}/>
        </svg>
    )
}