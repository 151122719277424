import { ToastContainer, toast } from 'react-toastify';
import {useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Panel from "../layout/components/Panel";
import Input from "../layout/components/Input";
import Button from "../layout/components/Button";
import { Auth } from '../common/AuthProvider';

export function Register () {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");   
    const[loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const handleRegister = () => {
        if (email === "" || password === "") {
            toast.error("Email and Password are required", {})
            return;
        }
        setLoading(true)
        Auth.register({email: email, password: password}).then((response) => {
            toast.success("Account created successfully", {})
            setLoading(false)
            navigate('/register/success')
        }).catch((error) => {
            setLoading(false)
            toast.error(error.message.error, {})
        })
    }

    return (
        <>
            <ToastContainer/>
            <Panel title="Create an Account">      
                <p className="text-normal text-center text-general-80">Have an Account? &nbsp;
                    <Link to="/login" className="text-primary-100">Sign In</Link>
                </p>
                <div className="form-group mb-4 mt-8 text-center">
                    <Input
                        label="Email"
                        type="email"
                        placeholder="Enter Email Address"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="form-group mb-4 text-center">
                    <Input
                        label="Password"
                        type="password"
                        id="password"
                        placeholder="Enter Password"
                        onChange={(e) => setPassword(e.target.value)}
                        aria-describedby="passwordHelpBlock"
                    />
                </div>
                <Button
                    className="w-full mt-4"
                    variant="primary" 
                    disabled={loading}
                    onClick={() => handleRegister()}  
                >Create Account</Button>
                <p className="text-normal text-center mt-4">
                    By creating account, you agree to our <Link to="/tos" className="text-primary-100">Terms of Service</Link>
                </p>
            </Panel>
        </>
    )
}