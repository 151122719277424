import config from "../config";
import { jwtDecode } from 'jwt-decode'
const apiUrl = config.apiGateway.URL

export const Auth = {
    register: ({ email, password }) => {
        const request = new Request(`${apiUrl}/register`, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    return response.json()
                    .then(text => Promise.reject({
                        error: new Error(`Error: ${response.statusText}. Details: ${text}`),
                        message: text,
                        response: response
                    }));
                }
                return response.json();
            });
    },
    verifyEmail: ({ token }) => {
        const request = new Request(`${apiUrl}/confirm-email`, {
            method: 'POST',
            body: JSON.stringify({ token }),
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    return response.json()
                    .then(text => Promise.reject({
                        error: new Error(`Error: ${response.statusText}. Details: ${text}`),
                        message: text,
                        response: response
                    }));
                }
                return response.json();
            });
    },
    resetPass: ({ email }) => {
        const request = new Request(`${apiUrl}/reset-password`, {
            method: 'POST',
            body: JSON.stringify({ email }),
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    return response.json()
                    .then(text => Promise.reject({
                        error: new Error(`Error: ${response.statusText}. Details: ${text}`),
                        message: text,
                        response: response
                    }));
                }
                return response.json();
            });
    },
    resetPassConfirm: ({ code, password }) => {
        const request = new Request(`${apiUrl}/reset-password-confirm`, {
            method: 'POST',
            body: JSON.stringify({ code, password }),
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    return response.json()
                    .then(text => Promise.reject({
                        error: new Error(`Error: ${response.statusText}. Details: ${text}`),
                        message: text,
                        response: response
                    }));
                }
                return response.json();
            });
    },
    login: ({ email, password }) => {
        const request = new Request(`${apiUrl}/login`, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    //throw new Error(response.statusText);
                    return Promise.reject(
                        {
                            error: new Error(response.statusText),
                            response: response 
                        }   
                    )
                }
                // console.log(response.json())
                return response.json();
            })
            .then(({ token, area, user }) => {
                setCookie('token', token);
                localStorage.setItem('token_exp', jwtDecode(token).exp);
                localStorage.setItem('user', JSON.stringify(user));
                return Promise.resolve({area: area});
            });
    },
    logout: () => {
        const token = getCookie('token');
        const request = new Request(`${apiUrl}/logout`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({ token: token}),
        });
        deleteCookie('token');
        return fetch(request)
    },
    checkAuth: () => {
        const tokenExp = localStorage.getItem('token_exp');
        if (tokenExp === undefined) {
            return Promise.reject();
        }
        if (Date.now() < tokenExp * 1000) {
            return Promise.resolve();
        }


        const token = getCookie('token');
        if (token === undefined)
            return Promise.reject();
        const request = new Request(`${apiUrl}/token-refresh`, {
            method: 'POST',
            body: JSON.stringify({ token: token}),
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                if (token != null) {
                    setCookie('token', token);
                }
                Promise.resolve();
            });   
    },
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            // do not return Promise.reject here or remove token as it WILL cause logout
        }
        return Promise.resolve();
    },
    getPermissions: params => Promise.resolve(),
};

const setCookie = (name, value, days = 7, path = '/') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString()
  document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path + '; SameSite=Strict;'
}

export const getCookie = (name) => {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=')
    return parts[0] === name ? decodeURIComponent(parts[1]) : r
  }, '')
}

const deleteCookie = (name, path) => {
  setCookie(name, '', -1, path)
}

