import { Link, useLocation } from "react-router-dom"
import {DashboardIcon} from './../images/MenuIcons';
import * as cx from 'classnames';

const MenuGroup = ({title, items}) => {
  const location = useLocation();
  return (
    items.map((item, index) => (
      <div key={index} className={cx("p-3 mt-2 rounded", location.pathname===item.path ?  " bg-primary-100 text-white": "text-general-80")}>
        <Link to = {item.path}>
          <div className="flex justify-start">
            {location.pathname===item.path ? item.iconActive : item.icon}
            <div className="ml-2 w-full font-semibold text-sm pt-1">{item.title}</div>
          </div>
        </Link>
      </div>
    ))
  )
}

export const AdminLeftMenu = () => {

  const menuItems1 = [
    {icon: <DashboardIcon fill="general-70" />, iconActive: <DashboardIcon fill="white"/>, path: "/admin/dashboard", title: "My Dashboard"},
    {icon: <DashboardIcon fill="general-70" />, iconActive: <DashboardIcon fill="white"/>, path: "/admin/esg_indicators", title: "ESG Indicators"},
    {icon: <DashboardIcon fill="general-70" />, iconActive: <DashboardIcon fill="white"/>, path: "/admin/esg_data_groups", title: "ESG Data Groups"},
  ]

  const menuItems2 = [
   
  ]

  return (
      <div className="bg-[#F5F6FA] w-full">
        <div className="flex flex-col items-left mt-4 mx-4">
          <MenuGroup title="Main" items={menuItems1}/>       
          <div className="text-general-60 text-sm mt-8 mb-6 ml-2">Support</div>
          <MenuGroup title="Main" items={menuItems2}/>
        </div>
      </div>
  )
}