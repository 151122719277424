
import { Company } from "./Company.jsx";
import BackendProvider from "./../../common/BackendProvider";
import { useEffect, useState } from 'react';
import {toast, ToastContainer} from "react-toastify";
import Table from "./../../layout/Table.jsx";
import { format } from "d3";

export const BestPerformers = () => {
  const[companies, setCompanies] = useState([])
  const[loading, setLoading] = useState(true)
  useEffect(() => {
        setLoading(true)
        BackendProvider.getList("companies", {filter: {hasprice: 0}, pagination:{page:1, perPage:7}}).then((response) => {
            setCompanies(response.data.companies)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading companies", {})
                setLoading(false)
            }
        )
  },[])
    return (
        <div className=" w-full mt-8 gap-1 rounded-lg bg-white">
          <div className="text-lg font-bold mb-8 ml-6 mt-6">
            Best Performers
          </div>
          <div className="px-4">
            <Table
              columns={["Name","Last Price","24h Change", "24h High", "24h Low"]}
              keys={[
                {name: 'name', type: 'text', format: (d) => (d), class: "text-left"},
                {name: 'last_price', type: 'text', format: (d) => format(",.2f")(d), class: "text-right"},
                {name: 'change_24h_percent', type: 'text', format: (d) => format(",.2f")(d) + "%", class: "text-right"},
                {name: 'high_24h', type: 'text', format: (d) => format(",.2f")(d), class: "text-right"},
                {name: 'low_24h', type: 'text', format: (d) => format(",.2f")(d), class: "text-right"}
              ]}
              data={companies}
              readOnly={true}
              />
          </div>
          
        </div>
    )
}