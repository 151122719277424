import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Panel from '../layout/components/Panel';
import Input from '../layout/components/Input';
import Button from '../layout/components/Button';
import { Auth } from '../common/AuthProvider';
import { ToastContainer, toast } from 'react-toastify';

export function ResetPass () {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const handleResetPass = () => {
        setLoading(true);
        Auth.resetPass({email: email})
            .then((response)=>{
                toast.success('If the email you specified is correct you will receive the instructions on how to reset your password');
                setLoading(false);
            })
            .catch((error)=>{
                toast.error(error.message.error, {})
                setLoading(false);
            })
    };
    return (
        <>
            <ToastContainer/>
            <Panel title="Reset Password">
                <p className="text-normal text-center text-general-80">Enter your email address and we will send you a link to reset your password</p>
                <div className="form-group mb-4 mt-8 text-center">
                    <Input
                        label="Email"
                        type="email"
                        placeholder="Enter Email Address"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <Button
                    className="w-full mt-4"
                    variant="primary" 
                    onClick={() => handleResetPass()}  
                >Request Password Reset Link</Button>
                <p className="text-normal text-center mt-4">
                    <Link to="/login" className="text-primary-100">Back to login screen</Link>
                </p>
            </Panel>
        </>
    )
}