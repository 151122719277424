import React, { useState } from 'react';
import Panel from '../layout/components/Panel';
import { Auth } from '../common/AuthProvider';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Input from '../layout/components/Input';
import Button from '../layout/components/Button';

export function ResetPassConfirm () {
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState('');
    const { code } = useParams();
    const [success, setSuccess] = useState(false);

    const handleResetPass = () => {
        Auth.resetPassConfirm({code: code, password: password})
            .then((response)=>{
                setSuccess(true);
            })
            .catch((err)=>{
                setError(err.message);
            })
    }

    return (
        <>
            <Panel title="Reset Password">
                {success ? (
                    <p className="text-normal text-center text-general-80">Password successfully reset. <Link to="/login">Login</Link></p>
                ) : (
                    <>
                        <Input
                            label="Please enter your new password"
                            type="password"
                            placeholder="EnterNew Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                            className="w-full mt-4"
                            variant="primary" 
                            onClick={() => handleResetPass()}  
                        >Reset Password</Button>
                    </>
                )}
            </Panel>
        </>
    )
}