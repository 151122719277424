import Panel from "../layout/components/Panel";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from "react";
import { Auth } from '../common/AuthProvider';
import { useParams } from "react-router-dom";
import { useState } from "react";

export function RegisterConfirmEmail () {
    const token = useParams().code;
    const[isConfirmed, setIsConfirmed] = useState(false)
    const[loading, setLoading] = useState(true)
    useEffect(() => {
        setLoading(true)
        Auth.verifyEmail({token: token}).then((response) => {
            toast.success("Account confirmed successfully. You can login now", {})
            setIsConfirmed(true)
            setLoading(false)
        }).catch((error) => {
            toast.error(error.message.error, {})
            setLoading(false)
        })
    }, [])

    return (
        <>
            <ToastContainer/>
            <Panel title="Email Address confirmation">
                {isConfirmed && !loading && <p className="text-normal text-center text-general-80">Your account was confirmed. You can <Link to="/login" className="text-primary-100">Sign In</Link> now.</p>}
                {!isConfirmed && !loading && <p className="text-normal text-center text-general-80">Your account could not be confirmed. Please try again.</p>}
                {loading && <p className="text-normal text-center text-general-80">Confirming your account...</p>}
            </Panel>
        </>
    )
}