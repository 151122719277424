import React from "react";

export const AdminDashboard = () => {
  return (
    <div className="p-8 border-l">
      <div className="text-3xl font-bold mb-8">Admin Dashboard</div>
      <div className="grid">
      </div>
    </div>
  );
};
