export const ArrowDown = ({fill}) => {
    if (fill === "green-100") {
        fill = "#06A561"
    } else if (fill === "red-100") {
        fill = "#F0142F"
    } else if (fill === "general-70") {
        fill = "#7E84A3"
    }

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="00 General / 01 Icons / 08 Arrows / 08 Down">
            <path id="color" fillRule="evenodd" clipRule="evenodd" d="M6.29289 9.29289C5.93241 9.65338 5.90468 10.2206 6.2097 10.6129L6.29289 10.7071L11.2929 15.7071C11.6534 16.0676 12.2206 16.0953 12.6129 15.7903L12.7071 15.7071L17.7071 10.7071C18.0976 10.3166 18.0976 9.68342 17.7071 9.29289C17.3466 8.93241 16.7794 8.90468 16.3871 9.2097L16.2929 9.29289L12 13.585L7.70711 9.29289C7.34662 8.93241 6.77939 8.90468 6.3871 9.2097L6.29289 9.29289Z" fill={fill}/>
            </g>
        </svg>
    )
}

export const ArrowUp = ({fill}) => {
    if (fill === "green-100") {
        fill = "#06A561"
    } else if (fill === "red-100") {
        fill = "#F0142F"
    }
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.29289 15.7071C5.93241 15.3466 5.90468 14.7794 6.2097 14.3871L6.29289 14.2929L11.2929 9.29289C11.6534 8.93241 12.2206 8.90468 12.6129 9.2097L12.7071 9.29289L17.7071 14.2929C18.0976 14.6834 18.0976 15.3166 17.7071 15.7071C17.3466 16.0676 16.7794 16.0953 16.3871 15.7903L16.2929 15.7071L12 11.415L7.70711 15.7071C7.34662 16.0676 6.77939 16.0953 6.3871 15.7903L6.29289 15.7071Z" fill={fill}/>
        </svg>
    )
}
